<script setup lang="ts">
const modal = defineModel<boolean>();
const notificationList = computed(() => notificationStore().getNotifications);
</script>

<template>
  <ui-side-modal v-model="modal" :title="$t('notification.notifications')">
    <div v-if="notificationList.length === 0" class="flex items-center justify-center pt-4">
      <p class="text-gray-500">
        {{ $t('notification.no_notification') }}
      </p>
    </div>
    <div v-else class="space-y-2">
      <notification
        v-for="notificationData in notificationList"
        :key="notificationData.id"
        :notification="notificationData"
        @close="modal = false"
      />
    </div>
  </ui-side-modal>
</template>
