<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';

defineProps<{
  title: string;
}>();

const modal = defineModel<boolean>();
</script>

<template>
  <TransitionRoot as="template" :show="modal">
    <Dialog class="relative z-10" @close="modal = false">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              enter="transform transition ease-in-out duration-300"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-screen flex-col overflow-y-auto bg-white shadow-xl">
                  <div class="p-4 border-b border-gray-200">
                    <div class="flex items-center justify-between">
                      <p class="text-base font-semibold leading-6 text-gray-900">
                        {{ title }}
                      </p>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500" @click="modal = false">
                          <span class="absolute -inset-2.5" />
                          <ui-icon name="X" class="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative flex-1 p-4">
                    <slot />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
