<script setup lang="ts"></script>

<template>
  <div class="flex h-full dark:text-white">
    <nav-menu />
    <main class="flex flex-1 flex-col h-screen min-w-0">
      <div class="flex flex-col w-full h-full bg-gray-50">
        <slot />
      </div>
    </main>
  </div>
</template>
