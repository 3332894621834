<script setup lang="ts">
import dayjs from 'dayjs';
import type { Notification } from '~/types/notification';
import type { User } from '~/types/user';

// Composables
const router = useRouter();

// Props & Emits
const emits = defineEmits(['close']);
const props = defineProps<{
  notification: Notification;
}>();

// Methods
const notificationClick = async () => {
  await notificationStore().setNotificationAsRead(props.notification.id);
  emits('close');
  router.push(props.notification.url);
};

// Computed
const user = computed((): User => {
  return userStore().getUser(props.notification.created_by);
});
</script>

<template>
  <div class="w-full relative p-2 border bg-gray-50 rounded-md flex">
    <ui-avatar v-if="notification.created_by === 'nrjx'" class="w-9 h-9" photo-url="assets/logo.jpg" />
    <div v-else>
      <ui-avatar
        v-if="user"
        class="w-9 h-9 text-lg font-light"
        :firstname="user.first_name"
        :lastname="user.last_name"
        :photo-url="user.picture_url"
      />
    </div>

    <div class="ml-4">
      <!-- User comment an incident -->
      <div v-if="notification.type === 'incident-comment'">
        <p class="text-regular font-semibold text-gray-600 mb-1">
          {{ $t('notification.a_user_comment_your_incident') }}
        </p>
        {{ user }}
        <p v-if="user" class="text-sm text-gray-500">
          {{
            $t('notification.user_add_a_comment_to_the_uer', {
              user: user.first_name + ' ' + user.last_name,
            })
          }}
        </p>
        <div class="pt-2 flex justify-between items-center">
          <p class="text-xs text-gray-400">{{ $t('globa.at') }} {{ dayjs(notification.created_at).format('DD/MM/YYYY') }}</p>
          <ui-button color="secondary" size="xs" @click="notificationClick">
            {{ $t('notification.see_incident') }}
          </ui-button>
        </div>
      </div>

      <!-- Incident created by NRJX -->
      <div v-if="notification.type === 'incident-create-by-nrjx'">
        <p class="text-regular font-semibold text-gray-900 mb-1">
          {{ $t('notification.an_alert_has_been_triggered') }}
        </p>
        <p class="text-sm text-gray-500">
          {{
            $t('notification.an_alert_has_been_triggered_description', {
              value: notification.data.incident?.alert?.name,
            })
          }}
        </p>
        <div class="pt-2 flex justify-between items-center">
          <p class="text-xs text-gray-400">{{ $t('globa.at') }} {{ dayjs(notification.created_at).format('DD/MM/YYYY') }}</p>
          <ui-button color="secondary" size="xs" @click="notificationClick">
            {{ $t('notification.see_incident') }}
          </ui-button>
        </div>
      </div>

      <!-- Incident resolve by an other user -->
      <div v-if="notification.type === 'incident-resolve'">
        <p class="text-regular font-semibold text-gray-900 mb-1">
          {{ $t('notification.your_incident_has_been_resolved') }}
        </p>
        <p class="text-sm text-gray-500">
          {{
            $t('notification.user_has_resolved_the_incident_named', {
              user: user.first_name + ' ' + user.last_name,
              incident: notification.data.incident?.name,
            })
          }}
        </p>
        <div class="pt-2 flex justify-between items-center">
          <p class="text-xs text-gray-400">{{ $t('globa.at') }} {{ dayjs(notification.created_at).format('DD/MM/YYYY') }}</p>
          <ui-button color="secondary" size="xs" @click="notificationClick">
            {{ $t('notification.see_incident') }}
          </ui-button>
        </div>
      </div>

      <!-- Action created by an other user and set you has owner -->
      <div v-if="notification.type === 'action-create'">
        <p class="text-regular font-semibold text-gray-900 mb-1">
          {{ $t('notification.an_action_has_been_created') }}
        </p>
        <p v-if="user" class="text-sm text-gray-500">
          {{
            $t('notification.user_set_you_has_owner_of_action', {
              user: user.first_name + ' ' + user.last_name,
              action: notification.data.action?.name,
            })
          }}
        </p>
        <div class="pt-2 flex justify-between items-center">
          <p class="text-xs text-gray-400">{{ $t('globa.at') }} {{ dayjs(notification.created_at).format('DD/MM/YYYY') }}</p>
          <ui-button color="secondary" size="xs" @click="notificationClick">
            {{ $t('notification.see_action') }}
          </ui-button>
        </div>
      </div>

      <!-- Incident created by an other user -->
      <div v-if="notification.type === 'incident-create-by-user'">
        <p class="text-regular font-semibold text-gray-900 mb-1">
          {{ $t('notification.an_incident_has_been_created') }}
        </p>
        <p class="text-sm text-gray-500">
          {{
            $t('notification.user_has_created_the_incident_named', {
              user: user.first_name + ' ' + user.last_name,
              incident: notification.data.incident?.name,
            })
          }}
        </p>
        <div class="pt-2 flex justify-between items-center">
          <p class="text-xs text-gray-400">{{ $t('globa.at') }} {{ dayjs(notification.created_at).format('DD/MM/YYYY') }}</p>
          <ui-button color="secondary" size="xs" @click="notificationClick">
            {{ $t('notification.see_incident') }}
          </ui-button>
        </div>
      </div>
    </div>
  </div>
</template>
